<template>
  <div class="form_box">
    <div class="box">
      <div class="three">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <div class="box1">
            <el-form-item label="任务名称" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="试卷名称" prop="paperId" placeholder="请选择">
              <el-select v-model="ruleForm.paperId">
                <el-option v-for="item in paperList" :key="item.id" :value="item.id" :label="item.title" />
              </el-select>
            </el-form-item>
          </div>
          <div class="box1">
            <el-form-item label="开始时间" prop="beginTime">
              <el-date-picker v-model="ruleForm.beginTime" type="datetime" placeholder="选择日期时间" style="width: 100%">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker v-model="ruleForm.endTime" type="datetime" placeholder="选择日期时间" style="width: 100%">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="重复答题次数" prop="answerTimeLimit">
              <el-input v-model="ruleForm.answerTimeLimit"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="tips">未推送人员,勾选推送(不可选中的为已推送人员,推送后不可修改)</div>
        <div class="operate_box">
          <el-input placeholder="用户姓名" style="width: 20%;margin-left:5px" v-model="searchForm.trueName"></el-input>
          <!-- <el-select v-model="regionCode" @change="getAreaCode" @focus="getArea" placeholder="请选择地区" filterable clearable>
            <el-option v-for="item in areaData" :value="item.regionCode" :label="item.area"/>
          </el-select>
          <el-select v-model="searchForm.communityId" filterable clearable placeholder="请选择社区" style="margin: 0 5px"
            @change="getGroupList">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="searchForm.groupId" clearable placeholder="请选择小组">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select> -->
          <el-button type="primary" class="el-icon-search" style="margin-left: 10px;" @click="searchBtn">搜索</el-button>
        </div>
        <div class="tbale_box_left">
          <el-table v-loading="leftLoading" :data="tableData" @selection-change="handleSelectionChange" border
            row-key="id" ref="multipleTable" style="width: 100%" height="100%">
            <el-table-column type="selection" :selectable="selectable1" :reserve-selection="true" width="55">
            </el-table-column>
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column prop="trueName" label="姓名" width="100">
            </el-table-column>
            <el-table-column prop="roleName" label="职位">
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号">
            </el-table-column>
          </el-table>
        </div>
        <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :page-size=pageBox.pageSize :page-sizes=pageSizes layout="total,sizes, prev, pager, next"
          :total=pageBox.total>
        </new-pagination>
      </div>
      <div class="four">
        <div class="tips">推送人员，勾选取消（不可选中的为已推送人员，推送后不可修改）</div>
        <div class="tbale_box_right">
          <el-table v-loading="rightLoading" :data="dataSelect" border row-key="id" ref="selectTable"
            @selection-change="clearSelection" style="width: 100%" height="100%">
            <el-table-column type="selection" :selectable="selectable" width="55">
            </el-table-column>
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column prop="trueName" label="姓名" width="100">
            </el-table-column>
            <el-table-column prop="communityName" label="社区">
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="sub_btn_box">
      <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="cancelFrom('ruleForm')">取消</el-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import { chinaArea } from '@/utils/china-area-data'
import moment from 'moment'
import debounce from 'lodash/debounce';
export default {
  name: 'edit-from',
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      leftLoading: false,
      rightLoading: false,
      areaData: chinaArea,
      communityList: [],
      tableData: [],
      dataSelect: [],
      ruleForm: {
        title: '',
        paperId: '',
        endTime: '',
        beginTime: '',
        answerTimeLimit: '',
        userList: []
      },
      rules: {
        title: [
          { required: true, message: '不为空', trigger: 'blur' },
        ],
        paperId: [
          { required: true, message: '不为空', trigger: 'change' },
        ],
        endTime: [
          { required: true, message: '不为空', trigger: 'change' },
        ],
        beginTime: [
          { required: true, message: '不为空', trigger: 'change' },
        ],
        answerTimeLimit: [
          { required: true, message: '不为空', trigger: 'change' },
        ],
      },
      searchForm: {
        trueName: "",
        communityId: null,
        groupId: null
      },

      regionCode: '',
      pageBox: {
        pageSize: 200,
        pageNum: 1,
        total: 0
      },
      selectIds: [],
      paperList: [],
      groupList: [],
      pageSizes: [200, 500, 1000]
    }
  },
  created() {
    var _this = this
    _this.getResidentList()
    _this.examPapeListGet()
    if (_this.operateBox.row != null) {
      _this.ruleForm = this.operateBox.row
      _this.getexamTaskDoctors(this.operateBox.row.id)
    }

  },
  methods: {
    //已经选择的用户禁用
    selectable1(row, index) {
      if (row.selected != 1) {
        return true
      }
    },
    selectable(row, index) {
      if (row.selected != 1) {
        return true
      }
    },
    //获取试卷列表
    async examPapeListGet() {
      let searchForm = {
        title: '',
        type: null
      }
      let pageBox = {
        pageSize: 99999,
        pageNum: 1,
      }
      try {
        let res = await api.getExamList(this.searchForm, pageBox)
        if (res.code == 200 && res.rows != '') {
          this.paperList = res.rows
        }
      } catch (e) {

      }
    },

    async getResidentList() {
      //获取医生列表
      var _this = this
      this.leftLoading = true
      try {
        let res = await api.getPushDoctors(this.searchForm, this.pageBox)
        if (res.code == 200) {
          console.log(res.rows);
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e)
      } finally {
        this.leftLoading = false
      }
    },

    //获取医生用于回显
    async getexamTaskDoctors(id) {
      this.rightLoading = true
      try {
        let res = await api.examTaskDoctors(id)
        if (res.code == 200) {
          this.dataSelect = JSON.parse(JSON.stringify(res.data))
          this.unchangedSelect = JSON.parse(JSON.stringify(res.data))
          let ids = []
          res.data.forEach(item => {
            ids.push(item.id)
          })
          this.selectIds = ids
        }
      } catch (e) {

      } finally {
        this.rightLoading = false
      }
    },
    getArea() {
      this.areaData = this.$store.state.userArea
    },
    async getAreaCode(val) {
      this.searchForm.communityId = null
      let data = {
        code: val,
      }
      let pageBox = {
        pageNum: 1,
        pageSize: 99999
      }
      let res = await api.getCommunitySystemUser(data, pageBox)
      if (res.code == 200 && res.rows) {
        this.communityList = res.rows
      } else {
        this.communityList = []
      }
    },
    //获取小组
    async getGroupList(val) {
      this.searchForm.groupId = ''
      try {
        let res = await api.getGroupListByCompId(val)
        if (res.code == 200) {
          this.groupList = res.data
        }
      } catch (e) {
      }
    },

    //条件查询
    searchBtn() {
      this.getResidentList()
    },
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getResidentList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getResidentList()
    },
    handleSelectionChange(val) {
      if (this.operateBox.row != null) { // 修改操作，需要保证原有的数组不变
        const mergedArray = this.unchangedSelect.concat(val)
        const uniqueMap = new Map()
        console.log(mergedArray)
        mergedArray.forEach(item => {
          uniqueMap.set(item.id, item)
        })
        const data = Array.from(uniqueMap.values())
        this.dataSelect = data
        console.log(this.dataSelect)
      } else {
        this.dataSelect = val
      }

      const ids = this.dataSelect.map(item => item.id)
      this.ruleForm.userList = ids
    },

    // 复选
    reselectFun(dataArr, IDArr) {
      console.log(111, dataArr, IDArr)
      var _this = this
      _this.$nextTick(function () {
        dataArr.forEach(row => {
          if (IDArr.includes(row.id)) {
            _this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      });
    },

    clearSelection(val) {
      val.forEach(row => {
        this.$refs.multipleTable.toggleRowSelection(row);
      });
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.userList == '') {
            this.$message.warning('请选择至少一位居民参与考试任务！')
            return false
          }
          if (this.operateBox.operate == 'add') {
            if (
              this.ruleForm.endTime.getTime() <
              this.ruleForm.beginTime.getTime()
            ) {
              return this.$message.warning('结束时间必须大于开始时间')
            }
            this.addTask({
              title: this.ruleForm.title,
              paperId: this.ruleForm.paperId,
              eTime: moment(this.ruleForm.endTime).format("YYYYMMDDHHmmss"),
              sTime: moment(this.ruleForm.beginTime).format("YYYYMMDDHHmmss"),
              answerTimeLimit: Number(this.ruleForm.answerTimeLimit),
              userList: this.ruleForm.userList,
              type: 1
            })
          } else {
            if (
              new Date(this.operateBox.row.endTime) <
              new Date(this.operateBox.row.beginTime)
            ) {
              return this.$message.warning('结束时间必须大于开始时间')
            }
            this.updateTask({
              title: this.ruleForm.title,
              paperId: this.ruleForm.paperId,
              eTime: moment(this.ruleForm.endTime).format("YYYYMMDDHHmmss"),
              sTime: moment(this.ruleForm.beginTime).format("YYYYMMDDHHmmss"),
              answerTimeLimit: Number(this.ruleForm.answerTimeLimit),
              userList: this.ruleForm.userList,
              id: this.ruleForm.id,
              type: 1
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),

    //新增任务
    async addTask(data) {
      try {
        let res = await api.examTaskAdd(data)
        if (res.code == 200) {
          this.$message.success('新增成功！')
          this.$emit('closed')
        } else {
          this.$message.warning('新增失败！')
        }
      } catch (e) {
        console.log(e);
        this.$message.warning(e.msg)
      }
    },
    //更新任务
    async updateTask(data) {
      try {
        let res = await api.examTaskUpdate(data)
        if (res.code == 200) {
          this.$message.success('修改成功！')
          this.$emit('closed')
        } else {
          this.$message.warning('修改失败！')
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    cancelFrom() {
      this.$emit('closed')
    }
  },
  watch: {
    tableData: {
      handler(newArr, oldArr) {
        var _this = this
        if (_this.selectIds.length > 0 && newArr.length > 0) {
          newArr.forEach(item => {
            if (_this.selectIds.includes(item.id)) {
              item.selected = 1
            }
          })
          _this.reselectFun(newArr, _this.selectIds)
        }
      },
      deep: true
    },
    selectIds: {
      handler(newArr, oldArr) {
        var _this = this
        if (_this.tableData.length > 0 && newArr.length > 0) {
          _this.tableData.forEach(item => {
            if (newArr.includes(item.id)) {
              item.selected = 1
            }
          })
          _this.reselectFun(_this.tableData, newArr)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
.form_box {
  height: 600px;

  .box {
    height: calc(100% - 46px);
    display: flex;

    .three {
      width: 50%;
      height: 100%;
      margin-right: 12px;

      .box1 {
        display: flex;
      }

      .tbale_box_left {
        height: calc(100% - 200px);
      }

      .tips {
        height: 36px;
        line-height: 36px;
      }

      .operate_box {
        height: 36px;
        line-height: 36px;
        display: flex;
        align-items: center;
      }
    }

    .four {
      width: calc(50% - 12px);
      height: 100%;

      .tbale_box_right {
        height: calc(100% - 62px);
      }

      .tips {
        height: 36px;
        line-height: 36px;
      }
    }
  }

  .sub_btn_box {
    height: 36px;
    margin-top: 10px;
    text-align: right;
  }
}
</style>
