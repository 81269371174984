<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>医生考试任务下发</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input placeholder="试卷名称" v-model="searchForm.title"></el-input>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchBtn">搜索</el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addTest">添加</el-button>
        <el-button type="danger" :plain="true" icon="el-icon-delete" @click="delMore">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border @selection-change="handleSelectionChange" style="width: 100%" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="title" label="任务名称" />
        <el-table-column prop="paperName" label="试卷名称" />
        <el-table-column prop="wechatPaperVo.questionSize" label="试题数量" width="80" />
        <el-table-column prop="wechatPaperVo.questionScoreSum" label="试卷总分" width="80" />
        <el-table-column prop="userCount" label="已推送人数" width="120" />
        <el-table-column prop="createTime" label="创建时间" width="180" />
        <el-table-column prop="creator" label="创建人" width="180" />
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="editTask(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <!--<el-tooltip class="item" effect="dark" content="推送" placement="top">-->
            <!--<el-button type="primary" icon="el-icon-s-promotion" circle></el-button>-->
            <!--</el-tooltip>-->
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" circle icon='el-icon-delete' @click="del(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--新增编辑-->
    <el-dialog :visible.sync="editDialog" width="90%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style" @close="closed">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" @closed="closed" :key="timer"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import EditFrom from './components/edit-from'
import api from '@/api/index'
export default {
  name: 'index',
  components: { EditFrom },
  data() {
    return {
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '添加考试任务',
        operate: 'add',
        row: null
      },
      timer: null,
      searchForm: {
        title: ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      delIds: null
    }
  },
  created() {
    this.getTaskList()
  },
  methods: {

    //获取列表
    async getTaskList() {
      try {
        let res = await api.getExamTaskListDoctor(this.searchForm, this.pageBox)
        if (res.code == 200) {
          console.log(res);
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {

      }
    },

    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getTaskList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getTaskList()
    },
    //条件查询
    searchBtn() {
      this.getTaskList()
    },
    // 新增样例
    addTest() {
      this.timer = new Date().getTime()
      this.operateBox = {
        title: '添加考试任务',
        operate: 'add',
        row: null
      }
      this.editDialog = true
    },
    editTask(row) {
      this.timer = new Date().getTime()
      this.operateBox = {
        title: '修改考试任务',
        operate: 'edit',
        row: row
      }
      this.editDialog = true
    },
    closed() {
      this.editDialog = false
      this.getTaskList()
    },
    //选中要删除的数据
    handleSelectionChange(val) {
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delIds = ids
    },
    //单个删除
    del(row) {
      console.log(row)
      this.$confirm('确定删除改数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delList([row.id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    //批量删除按钮
    delMore() {
      if (this.delIds == null) {
        this.$message.info('请至少选中一条数据进行删除操作')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delList(this.delIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //删除
    async delList(ids) {
      try {
        let res = await api.examTaskDel(ids)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getTaskList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      } catch (e) {
        this.$message.error(e)
      }
    },
  },
  watch: {}
}
</script>

<style scoped></style>
